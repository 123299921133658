import React, { useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
    AnimatePresence,
    useScroll,
    useTransform,
    useSpring,
    motion,
} from 'framer-motion';
import { useMedia } from 'react-use';
import { useIntersectionObserver, useWindowSize } from '@madeinhaus/hooks';
import Img from 'components/ui/Img';
import { lerp, breakpoints } from 'utils';
import styles from '../SectionHeader.module.scss';
import { themes } from './themes';

const Figure = ({ theme }) => {
    const requestRef = useRef(null);

    const [offsetTop, setOffsetTop] = useState(0);

    const config = themes[theme];

    const bufferTop = 600;
    const buffer = -300;
    const springOptions = {
        damping: 10,
        stiffness: 50,
        mass: 1,
    };

    const { scrollY } = useScroll();
    const { width } = useWindowSize();

    const isMedium = useMedia(`(min-width: ${breakpoints.medium}px)`, false);

    const [inView, ref] = useIntersectionObserver();

    // element position
    const y = useSpring(
        useTransform(
            scrollY,
            [offsetTop - bufferTop, offsetTop + buffer],
            ['-800%', '0%']
        ),
        springOptions
    );

    // shadow scale
    const scaleX = useSpring(
        useTransform(
            scrollY,
            [offsetTop - bufferTop, offsetTop + buffer],
            [0.3, 1]
        ),
        springOptions
    );

    // shadow opacity
    const opacity = useSpring(
        useTransform(
            scrollY,
            [offsetTop - bufferTop, offsetTop + buffer],
            [0.3, 1]
        ),
        springOptions
    );

    let pageX = useRef(width / 2);
    let xRatio = useRef(0.5);

    const onMouseMove = useCallback(e => {
        pageX.current = e.pageX;
    }, []);

    const onFrame = useCallback(() => {
        // normalize event page x and y to a value of 0 - 1
        // and interpolate the values with a lerp threshold
        xRatio.current = lerp(
            xRatio.current,
            pageX.current / window.innerWidth,
            0.05
        );

        // multiply or divide ratio number for movement number
        const xM = -(xRatio.current * 10);

        // apply movement number as percentage to ref
        if (ref.current) {
            ref.current.style.transform = `translateX(${xM + 15}%)`;
        }

        requestRef.current = requestAnimationFrame(onFrame);
    }, [ref]);

    const onResize = useCallback(() => {
        if (!ref.current) return;

        setOffsetTop(ref?.current?.offsetTop);
    }, [ref]);

    useEffect(() => {
        if (isMedium) {
            document.addEventListener('mousemove', onMouseMove);
            requestRef.current = requestAnimationFrame(onFrame);
        }
        return () => {
            document.removeEventListener('mousemove', onMouseMove);
            cancelAnimationFrame(requestRef.current);
        };
    }, [isMedium, onFrame, onMouseMove]);

    useEffect(() => {
        window.addEventListener('resize', onResize);
        onResize();

        return () => {
            window.addEventListener('resize', onResize);
        };
    }, [onResize]);

    return (
        <figure
            ref={ref}
            className={cx(
                styles.figure,
                config.className && styles[config.className],
                config.sticky && styles.sticky,
                {
                    [styles.isInView]: inView,
                }
            )}
        >
            <AnimatePresence mode="wait">
                <motion.div
                    key={`figure-${theme}`}
                    initial={{ opacity: 0 }}
                    animate={{
                        opacity: 1,
                        transition: { duration: 0.3 },
                    }}
                    exit={{ opacity: 0, transition: { duration: 0.15 } }}
                    className={styles.figureInner}
                >
                    <div className={styles.imageContainer}>
                        {!config.animated && (
                            <motion.div initial={{ y: 0 }} style={{ y }}>
                                <Img
                                    src={config.url}
                                    width={config.dimensions.width}
                                    height={config.dimensions.height}
                                    sizes={`(max-width: ${breakpoints.medium}px) 80vw, 25vw`}
                                />
                            </motion.div>
                        )}
                        {config.animated && (
                            <motion.div initial={{ y: 0 }} style={{ y }}>
                                <video
                                    width={config.dimensions.width}
                                    height={config.dimensions.height}
                                    autoPlay
                                    muted
                                    loop
                                    playsInline
                                >
                                    <source
                                        src={`/video/objects/headings/${theme}/video.mov`}
                                        type="video/quicktime"
                                    />
                                    <source
                                        src={`/video/objects/headings/${theme}/video.webm`}
                                        type="video/webm"
                                    />
                                </video>
                            </motion.div>
                        )}
                    </div>
                    <div className={styles.shadowContainer}>
                        <motion.div
                            className={styles.shadow}
                            initial={{ scaleX: 1, opacity: 1 }}
                            style={{
                                scaleX,
                                opacity,
                                width: `${config.shadow.size}%`,
                                marginTop: `${config.shadow.distance}rem`,
                                marginLeft: `${config.shadow.offsetLeft}%`,
                                background: `radial-gradient(ellipse, rgba(0,0,0,0.25) 0%, rgba(0,0,0,0) 75%)`,
                            }}
                        />
                    </div>
                </motion.div>
            </AnimatePresence>
        </figure>
    );
};

Figure.propTypes = {
    theme: PropTypes.oneOf(Object.keys(themes)),
};

export default Figure;
