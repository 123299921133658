const defaultDimensions = {
    width: 2000,
    height: 2400,
};

export const themes = {
    soulAndScience: {
        url: '/images/heading/soulandscience.webp',
        sticky: false,
        dimensions: defaultDimensions,
        shadow: {
            size: 80,
            distance: 5,
        },
    },
    raccoon: {
        url: '/images/heading/raccoon.webp',
        sticky: false,
        dimensions: {
            width: 1010,
            height: 1572,
        },
        shadow: {
            size: 80,
            distance: 5,
        },
    },
    lightbulb: {
        url: '/images/heading/lightbulb.webp',
        sticky: true,
        dimensions: defaultDimensions,
        shadow: {
            size: 80,
            distance: 5,
        },
    },
    balloonSmile: {
        url: '/images/heading/balloon-smile.webp',
        sticky: false,
        dimensions: defaultDimensions,
        shadow: {
            size: 80,
            distance: 5,
        },
    },
    briefcase: {
        url: '/images/heading/briefcase.webp',
        sticky: false,
        dimensions: defaultDimensions,
        shadow: {
            size: 100,
            distance: 0,
            offsetLeft: 5,
        },
    },
    journal: {
        url: '/images/heading/journal.webp',
        sticky: false,
        dimensions: defaultDimensions,
        shadow: {
            size: 100,
            distance: 0,
        },
    },
    trophy: {
        url: '/images/heading/trophy.webp',
        sticky: false,
        dimensions: defaultDimensions,
        shadow: {
            size: 70,
            distance: 5,
        },
    },
    bike: {
        url: '/images/heading/bike.webp',
        className: 'bike',
        sticky: false,
        dimensions: defaultDimensions,
        shadow: {
            size: 100,
            distance: -2,
        },
    },
    metalDetector: {
        url: '/images/heading/metal-detector.webp',
        sticky: false,
        dimensions: defaultDimensions,
        shadow: {
            size: 100,
            distance: 8,
        },
    },
    burrito: {
        url: '/images/heading/burrito.webp',
        sticky: false,
        dimensions: defaultDimensions,
        shadow: {
            size: 70,
            distance: 0,
        },
    },
    fish: {
        url: '/images/heading/fish.webp',
        sticky: false,
        dimensions: defaultDimensions,
        shadow: {
            size: 70,
            distance: 0,
        },
    },
    hotdog: {
        url: '/images/heading/hotdog.webp',
        sticky: false,
        dimensions: defaultDimensions,
        shadow: {
            size: 70,
            distance: 0,
        },
    },
    pizza: {
        url: '/images/heading/pizza.webp',
        sticky: false,
        dimensions: defaultDimensions,
        shadow: {
            size: 90,
            distance: 0,
        },
    },
    compass: {
        url: '/images/heading/compass.webp',
        sticky: false,
        dimensions: {
            width: 1010,
            height: 1250,
        },
        shadow: {
            size: 100,
            distance: 0,
        },
    },
    glove: {
        url: '/images/heading/glove.webp',
        sticky: false,
        dimensions: defaultDimensions,
        shadow: {
            size: 70,
            distance: -3,
        },
    },
    'disco-ball': {
        animated: true,
        sticky: true,
        dimensions: {
            width: 1000,
            height: 1499,
        },
        shadow: {
            size: 70,
            distance: -8,
            offsetLeft: 10,
        },
    },
    coffee: {
        animated: true,
        sticky: true,
        dimensions: defaultDimensions,
        shadow: {
            size: 60,
            distance: 5,
            offsetLeft: 20,
        },
    },
    terrarium: {
        animated: true,
        sticky: false,
        dimensions: defaultDimensions,
        shadow: {
            size: 90,
            distance: 0,
            offsetLeft: 0,
        },
    },
    origami: {
        url: '/images/landing/origami.webp',
        sticky: true,
        dimensions: defaultDimensions,
        shadow: {
            size: 90,
            distance: -5,
        },
    },
    calculator: {
        url: '/images/heading/calculator.webp',
        sticky: false,
        dimensions: defaultDimensions,
        shadow: {
            size: 200,
            distance: -5,
            offsetLeft: 0,
        },
    },
    reel2reel: {
        url: '/images/heading/reel2reel.webp',
        sticky: false,
        dimensions: defaultDimensions,
        shadow: {
            size: 200,
            distance: 0,
            offsetLeft: 0,
        },
    },
    knittedHearts: {
        url: '/images/heading/knitted-hearts.webp',
        sticky: false,
        dimensions: defaultDimensions,
        shadow: {
            size: 90,
            distance: 0,
            offsetLeft: 0,
        },
    },
    audioCables: {
        url: '/images/heading/audio-cables-long.webp',
        sticky: true,
        dimensions: {
            width: 2000,
            height: 3000,
        },
        shadow: {
            size: 90,
            distance: 8,
            offsetLeft: 0,
        },
    },
    microphone: {
        url: '/images/heading/microphone.webp',
        sticky: true,
        dimensions: defaultDimensions,
        shadow: {
            size: 60,
            distance: 4,
            offsetLeft: 20,
        },
    },
};
