import React, { memo, useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ImgOptimized from 'components/misc/ImgOptimized';
import styles from './Img.module.scss';

const Img = ({ className, lazyFadeIn, debug, ...otherProps }) => {
    const imageRef = useRef(null);
    const [loaded, setLoaded] = useState(false);

    const onLoad = useCallback(() => {
        setLoaded(true);
    }, []);

    const classNames = cx(styles.root, className, {
        [styles.fadeIn]: lazyFadeIn,
        [styles.loaded]: loaded,
        [styles.debug]: debug,
    });

    return (
        <ImgOptimized
            ref={imageRef}
            className={classNames}
            onLoadingComplete={onLoad}
            onError={onLoad}
            {...otherProps}
        />
    );
};

Img.propTypes = {
    className: PropTypes.string,
    debug: PropTypes.bool,
    lazyFadeIn: PropTypes.bool,
};

export default memo(Img);
