import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Link from 'components/ui/Link';
import Text from 'components/ui/Text';
import Icon from 'components/ui/Icon';
import styles from './Button.module.scss';

const Button = ({ children, className, disabled, href, onClick }) => {
    const renderButton = () => (
        <>
            <Text className={styles.buttonText} theme="buttonLabel">
                {children}
            </Text>
            <Icon type="arrowButton" className={styles.buttonIcon} />
        </>
    );

    if (href) {
        return (
            <Link
                href={href}
                className={cx(styles.root, className)}
                disabled={disabled}
            >
                {renderButton()}
            </Link>
        );
    }
    return (
        <button
            className={cx(styles.root, className)}
            disabled={disabled}
            onClick={onClick}
        >
            {renderButton()}
        </button>
    );
};

Button.propTypes = {
    children: PropTypes.string.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    href: PropTypes.string,
    onClick: PropTypes.func,
};

Button.defaultProps = {
    className: '',
    disabled: false,
    href: '',
    onClick: () => {},
};

export default Button;
