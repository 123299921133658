import React from 'react';
import PropTypes from 'prop-types';
import Text from 'components/ui/Text';
import styles from './RichText.module.scss';

const propTypes = {
    children: PropTypes.node,
};

const defaultProps = {
    children: null,
};

// MARKS
export const Bold = ({ children }) => {
    return <b className={styles.bold}>{children}</b>;
};

export const Italic = ({ children }) => {
    return <i className={styles.italic}>{children}</i>;
};

export const Underline = ({ children }) => {
    return <u className={styles.underline}>{children}</u>;
};

Bold.propTypes = propTypes;
Bold.defaultProps = defaultProps;

Italic.propTypes = propTypes;
Italic.defaultProps = defaultProps;

Underline.propTypes = propTypes;
Underline.defaultProps = defaultProps;

// BLOCKS
export const BodyText = ({ children }) => (
    <Text as="p" theme="bodyCopy" className={styles.paragraph}>
        {children}
    </Text>
);

BodyText.propTypes = {
    ...propTypes,
    as: PropTypes.string,
};
BodyText.defaultProps = {
    ...defaultProps,
    as: 'span',
};

export const UnoderedList = ({ children }) => (
    <ul className={styles.unorderedList}>{children}</ul>
);

UnoderedList.propTypes = {
    ...propTypes,
};
UnoderedList.defaultProps = {
    ...defaultProps,
};

export const OrderedList = ({ children }) => (
    <ol className={styles.orderedList}>{children}</ol>
);

OrderedList.propTypes = {
    ...propTypes,
};
OrderedList.defaultProps = {
    ...defaultProps,
};

export const ListItem = ({ children }) => (
    <Text as="li" theme="bodyCopy" className={styles.listItem}>
        {children}
    </Text>
);
