import React from 'react';
import PropTypes from 'prop-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { MARKS, BLOCKS } from '@contentful/rich-text-types';
import {
    Bold,
    Italic,
    Underline,
    BodyText,
    UnoderedList,
    OrderedList,
    ListItem,
} from './defaults';

const defaultOptions = {
    renderMark: {
        [MARKS.BOLD]: text => <Bold>{text}</Bold>,
        [MARKS.ITALIC]: text => <Italic>{text}</Italic>,
        [MARKS.UNDERLINE]: text => <Underline>{text}</Underline>,
    },
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return <BodyText>{children}</BodyText>;
        },
        [BLOCKS.UL_LIST]: (node, children) => (
            <UnoderedList>{children}</UnoderedList>
        ),
        [BLOCKS.OL_LIST]: (node, children) => (
            <OrderedList>{children}</OrderedList>
        ),
        [BLOCKS.LIST_ITEM]: (node, children) => <ListItem>{children}</ListItem>,
    },
    renderText: text => {
        return text.split('\n').reduce((children, textSegment, index) => {
            return [...children, index > 0 && <br key={index} />, textSegment];
        }, []);
    },
};

const RichText = ({ richText, overrides }) => {
    const options = {
        renderMark: {
            ...defaultOptions.renderMark,
            ...overrides.renderMark,
        },
        renderNode: {
            ...defaultOptions.renderNode,
            ...overrides.renderNode,
        },
        renderText: overrides.renderText || defaultOptions.renderText,
    };

    if (richText) {
        return documentToReactComponents(richText, options);
    }

    return null;
};

RichText.propTypes = {
    richText: PropTypes.object.isRequired,
    overrides: PropTypes.object,
};

RichText.defaultProps = {
    overrides: {
        renderText: null,
    },
};

export default RichText;
