import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { BLOCKS } from '@contentful/rich-text-types';
import Button from 'components/ui/Button';
import RichText from 'components/ui/RichText';
import Text from 'components/ui/Text';
import styles from './SectionHeader.module.scss';

const SectionHeader = ({ headline, children, cta, invertLayout }) => {
    return (
        <div
            className={cx(styles.root, {
                [styles.invertLayout]: invertLayout,
            })}
        >
            <div className={styles.textContainer}>
                <RichText
                    richText={headline}
                    overrides={{
                        renderNode: {
                            [BLOCKS.HEADING_5]: (node, children) => {
                                return (
                                    <Text
                                        className={styles.eyebrow}
                                        as="span"
                                        theme="label"
                                    >
                                        {children}
                                    </Text>
                                );
                            },
                            [BLOCKS.HEADING_3]: (node, children) => {
                                return (
                                    <Text
                                        className={styles.headline}
                                        as="h3"
                                        theme="sectionHeadline"
                                    >
                                        {children}
                                    </Text>
                                );
                            },
                            [BLOCKS.PARAGRAPH]: (node, children) => {
                                return (
                                    <Text
                                        className={styles.bodyCopy}
                                        as="p"
                                        theme="bodyCopy"
                                    >
                                        {children}
                                    </Text>
                                );
                            },
                        },
                    }}
                />
                {cta && (
                    <Button className={styles.button} href={cta.url}>
                        {cta.label}
                    </Button>
                )}
            </div>

            {children}
        </div>
    );
};

SectionHeader.propTypes = {
    headline: PropTypes.object.isRequired,
    cta: PropTypes.object,
    invertLayout: PropTypes.bool,
};

SectionHeader.defaultPropTypes = {
    invertLayout: false,
};

export default SectionHeader;
