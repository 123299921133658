import React, { forwardRef, memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';

// Add https if starts with double slash (//)
function addHttps(src = '') {
    if (src.startsWith('//')) {
        return `https:${src}`;
    }
    return src;
}

const CONTENTFUL_MAX_WIDTH = 4000;

const ImgOptimized = forwardRef(
    (
        {
            alt = '',
            className,
            height = 0,
            onLoadingComplete,
            priority = false,
            sizes = null,
            src: srcProp,
            style,
            width = 0,
            draggable = true,
        },
        ref
    ) => {
        // Relative protocol URLs are not supported by Next.js
        const src = addHttps(srcProp);
        const w = Math.min(width, CONTENTFUL_MAX_WIDTH);
        const defaultSrc = w ? `${src}?w=${w}&fm=webp` : `${src}?fm=webp`;

        sizes === null &&
            console.warn(
                'The `sizes` attribute is technically optional per the spec, but it is highly recommended. Without it, the browser will not know which image to download. When you define `sizes`, you describe to the browser how the image will respond to different screen sizes, densities, resolutions, etc.',
                src
            );

        useEffect(() => {
            if (ref.current?.complete) {
                onLoadingComplete();
            }
        }, [onLoadingComplete, ref]);

        const renderImg = () => (
            <Image
                ref={ref}
                alt={alt}
                className={className}
                src={defaultSrc}
                sizes={sizes}
                width={width}
                height={height}
                priority={priority}
                draggable={draggable}
                style={style}
                onLoad={onLoadingComplete}
                onError={onLoadingComplete}
            />
        );

        return src ? renderImg() : null;
    }
);

ImgOptimized.displayName = 'ImgOptimized';

ImgOptimized.propTypes = {
    alt: PropTypes.string,
    className: PropTypes.string,
    draggable: PropTypes.bool,
    height: PropTypes.number,
    onLoadingComplete: PropTypes.func,
    priority: PropTypes.bool,
    sizes: PropTypes.string,
    src: PropTypes.string,
    style: PropTypes.object,
    width: PropTypes.number,
};

export default memo(ImgOptimized);
